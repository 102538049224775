<template>
  <VueApexCharts id="chart-company" type="bar" height="350" :width="this.width" :options="this.chartOptions"
                 :series="series" style="margin-left: 100px;"></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import {defineComponent} from "vue";

export default defineComponent({
  components: {
    VueApexCharts,
  },
  props: ['lable'],
  data() {
    return {
      width: "100%",
      series: this.lable.data,
      chartOptions: {
        colors: ['#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#E8684A', '#800000', '#9A6324', '#469990', '#e6194B', '#bfef45', '#42d4f4',
          '#911eb4', '#f032e6', '#a9a9a9', '#fabed4', '#ffd8b1', '#fffac8', '#aaffc3', '#dcbeff', '#000000'],
        title: {
          text: undefined,
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: 'red'
          },
        },

        chart: {
          dropShadow: {
            enabled: true,
            enabledOnSeries: undefined,
            top: 1000,
            left: 2000,
            blur: 3,
            color: '#000',
            opacity: 0.35
          },
          type: 'bar',
          height: 550,
          stacked: true,
          marginLeft: 500,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {}
          },
        },
        stroke: {
          width: -5,
          colors: ['#fff']
        },
        xaxis: {
          categories: this.lable.categories,
          fontsize: 560,
          labels: {
            formatter: function (val) {
              return null
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          },
          labels: {
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
          },
        },
        title: {
          text: 'CO2 (単位 ton), 他（単位 円)',
          align: 'right',
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString()
            }
          },
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: -23,
          fontSize: '15px',
        }
      },

    }
  },
})
</script>
<style>
#chart-company .apexcharts-legend {
  display: block !important;
}

#chart-company .apexcharts-toolbar {
  display: none !important;
}
</style>
