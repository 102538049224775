<template>
  <a-layout-header class="bg-light p-0 border-bottom d-flex justify-content-between">
    <div class="d-flex">
      <div style="font-size: 20px; padding-left: 30px">{{ data?.name_company }}</div>
      <div class="name-facility" style="font-size: 20px; padding-left: 15px">{{ data?.name_facility }}</div>
      <div class="faciliti2" style="float: right; padding-left: 20px">
        <div class="fac2">
          <select v-model="cardYear" @change="getMonth" :disabled="isLoading" style="width: 100px;">
            <option
                v-for="(year, i) in cardYearOptions"
                :key="year"
                :value="year"
            >
              {{ year }}
            </option>
          </select>
          <select v-model="month" @change="getMonth" :disabled="isLoading" style="margin-left: 10px; width: 100px">
            <option
                v-for="(month, i) in monthOptions"
                :key="month"
                :value="month"
            >
              {{ month }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div style="margin-right: 100px; font-size: 20px">
        {{ this.currYear }}年{{ this.currMonth }}月{{ this.currDay }}日<span class="ms-3">{{ this.currDD }}</span>
      </div>
      <Info/>
    </div>
  </a-layout-header>

  <a-layout-content class="p-4">
    <a-row :gutter="16" class="box">
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <div class="mt-3 pt-3 title-emissions">{{cardYear}}年{{month}}月 排出量</div>
          <div class="mt-3 title-co2 d-flex justify-content-center"><span class="number-co2">{{
              data?.total_co2_month
            }}</span><span
              class="ms-5 mt-2">tCO2</span></div>
          <div class="mt-3 faciliti d-flex justify-content-between row">
            <div class="col-6">
              <span class="lable-number">前月比</span><span class="ms-1">{{
                Math.abs(data?.total_co2_lastmonth)
              }}%</span><span
                :class="this.total_co2_lastmonth ? 'caret-up' : 'caret-down'"><caret-up-outlined
                v-show="total_co2_lastmonth"/><caret-down-outlined v-show="!total_co2_lastmonth"/></span>
            </div>
            <div class="col-6">
              <span class="lable-number amount-lastyear">前年同月比</span><span
                class="ms-1">{{ Math.abs(data?.total_co2_lastyear) }}%</span>
              <span :class="this.total_co2_lastyear ? 'caret-up' : 'caret-down'"><caret-up-outlined
                  v-show="total_co2_lastyear"/><caret-down-outlined v-show="!total_co2_lastyear"/></span>
            </div>
          </div>
          <div class="mt-4 hr">
            <hr>
          </div>
          <div class="mt-4 total-year"><span class="lable-number">年度合計</span><span
              class="ms-4">{{ data?.total_co2_year?.toLocaleString() }}</span><span
              class="ms-3">tCO2</span></div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <div class="mt-3 pt-3 title-expenses">{{cardYear}}年{{month}}月 料金</div>
          <div class="mt-3 expenses"><span
              class="number-expenses ms-5">￥ {{ totalMoney?.total_money_month?.toLocaleString() }}</span>
          </div>
          <div class="mt-3 faciliti d-flex justify-content-between row">
            <div class="col-6">
              <span class="lable-number">前月比</span><span
                class="ms-1">{{ Math.abs(totalMoney?.total_money_lastmonth) }}%</span><span
                :class="this.total_money_lastmonth ? 'caret-up' : 'caret-down'"><caret-up-outlined
                v-show="total_money_lastmonth"/><caret-down-outlined v-show="!total_money_lastmonth"/></span>
            </div>
            <div class="col-6">
              <span class="lable-number amount-lastyear">前年同月比</span><span
                class="ms-1">{{ Math.abs(totalMoney?.total_money_lastyear) }}%</span>
              <span :class="this.total_money_lastyear ? 'caret-up' : 'caret-down'"><caret-up-outlined
                  v-show="total_money_lastyear"/><caret-down-outlined v-show="!total_money_lastyear"/></span>
            </div>
          </div>
          <div class="mt-4 hr">
            <hr>
          </div>
          <div class="mt-4 total-year"><span class="lable-number">年度合計</span>
            <span class="ms-4">￥</span>
            <span class="ms-3">{{ totalMoney?.total_money_year?.toLocaleString() }}</span>
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <div class="mt-3 d-flex justify-content-between">
            <div class="title-co2-month pt-3"> 直近12ケ月CO2排出量推移</div>
            <div class="detail-chart fs-6">
              <router-link v-if="this.facility"
                           :to="{ name: 'home.detail-facility', params: {facilityId: this.facility }}">
                詳細
              </router-link>
            </div>
          </div>
          <div class="chart-detail">
            <Chart v-if="this.countcO2ByMonth && !isLoading" :lable="this.countcO2ByMonth"></Chart>
            <div v-else style="min-height: 255px"></div>
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <div class="mt-3 pt-5 lable-topic">{{ data?.topic }}　<span
              v-if="this.$store.state.role.role == '施設担当者'" @click="showModal"
              class="edit-topic me-3 mt-2"><edit-outlined/></span></div>
        </div>
      </a-col>
    </a-row>
    <div>
      <a-modal v-model:visible="visible" title="内容編集" @ok="updateTopic" ok-text="変更" cancel-text="キャンセル">
        <form @submit.prevent="updateTopic">
          <div class="modal-body">
            <a-textarea class="form-control"
                        v-model:value="titleFacility.topic"
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                        :class="[isInvalid('topic') ? 'border-danger' : '']"
            />
            <span class="text-danger" v-if="isInvalid('topic')">
                            {{ invalidMessages('topic')[0] }}
                            </span>
          </div>
        </form>
      </a-modal>
    </div>
    <div class="content mt-4">
      <div class="faciliti d-flex justify-content-between">
        <div class="fac1">建屋別CO2排出量</div>
        <div class="fac2 ms-5">
          <select v-model="cardYear2" @change="getByFacility" style="width: 100px;">
            <option
                v-for="(year, i) in cardYearOptions2"
                :key="year"
                :value="year"
            >
              {{ year }}
            </option>
          </select>
          <select v-model="monthCo2" @change="getByFacility" :disabled="isLoading"
                  style="margin-left: 10px; width: 100px">
            <option
                v-for="(month, i) in monthOptions"
                :key="month"
                :value="month"
            >
              {{ month }}
            </option>
          </select>
          <div style="margin-left: 450px; margin-top: -40px;" class="fs-6">
            <router-link v-if="this.facility"
                         :to="{ name: 'home.detail-co2-by-building', params: {facilityId: this.facility }}">
              詳細
            </router-link>
          </div>
        </div>
      </div>

      <div id="chart">
        <BarChart v-if="this.countcO2ByFacility && !loadChart" :lable="this.countcO2ByFacility"></BarChart>
        <div v-else style="min-height: 365px"></div>
      </div>
    </div>
    <div class="mt-4">
      <a-row :gutter="[16,16]" justify="space-between">
        <a-col class="gutter-row" :span="12">
          <div class="gutter-box alarm-lef">
            <div class="calendar me-3">
              <div class="title-calendar ps-3 pt-3">
                笑太くん　設備お知らせカレンダー
              </div>
              <calendar v-if="this.facility" :role="this.role" :facility="this.facility"></calendar>
            </div>
          </div>
        </a-col>
        <a-col class="gutter-row" :span="12">
          <div class="gutter-box ">
            <div class="alarm-top">
              <div class="title-alarm pt-4 ps-3 d-flex justify-content-between">
                <div>直近 5件アラーム</div>
                <div>
                  <router-link :to="{ name: 'alarm.list'}">
                    <div class="me-3 fs-6">もっと見る</div>
                  </router-link>
                </div>
              </div>
              <div class="mt-5">
                <a-table
                    :dataSource="this.alarm"
                    :columns="columns"
                    :pagination="false">
                  <template #bodyCell="{column, text, record}">
                    <template v-if="column.dataIndex === 'name'">
                      <router-link :to="{ name: 'equipment.edit', params: {equipmentId: record.equipment_id }}">
                        {{ record.name }}
                      </router-link>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
            <div class="" style="min-height:25px; background-color: #f0f2f5"></div>
            <div class="alarm-bottom mt-3">
              <div class="title-facility">
                  <div class="fw-bold fs-5 ms-4 mb-2">施設のデータ : {{ data?.name_facility  }}</div>
                  <div class="d-flex ">
                      <div class="ms-4 fs-6">＜エネルギー関連＞</div>
                      <div class="ms-4 fs-6">去年</div>
                      <div class="ms-5 fs-6">今年</div>
                  </div>
                  <div class="d-flex mt-1">
                      <div class="ms-4 fs-6 title-item">①CO2排出量</div>
                      <div class="fs-6 item">{{ maintainFacility.amountCo2LastYear?.toLocaleString() }}t</div>
                      <div class="fs-6 ms-4">{{ maintainFacility.amountCo2Year?.toLocaleString() }}t</div>
                  </div>
                  <div class="d-flex mt-1">
                      <div class="ms-4 fs-6 title-item">②電気料金</div>
                      <div class="fs-6 item">{{ maintainFacility.amountMoneyElectricLastYear?.toLocaleString() }}円</div>
                      <div class="ms-4 fs-6">{{ maintainFacility.amountMoneyElectricYear?.toLocaleString() }}円</div>
                  </div>
                  <div class="d-flex mt-1">
                      <div class="ms-4 fs-6 title-item">③創エネ量</div>
                      <div class="fs-6 item">{{ maintainFacility.amountEnergyLastYear?.toLocaleString() }}kW</div>
                      <div class="ms-4 fs-6 ">{{ maintainFacility.amountEnergyYear?.toLocaleString() }}kW</div>
                  </div>
                  <div class="mt-2 ms-4 fs-6">＜設備関連＞</div>
                  <div class="fs-6 ms-4 mt-2">① 今年の設備メンテナンス件数 <span class="fw-bold">{{ maintainFacility.countMaintenance?.toLocaleString() }}件 </span></div>
                  <div class="fs-6 ms-4 mt-1">② 今年のメンテナンス費用概算 <span class="fw-bold">{{ maintainFacility.countMoneyMaintenance?.toLocaleString() }}円</span></div>
                  <div class="fs-6 ms-4 mt-1">③今年の先月までの平均電気料金 <span class="fw-bold">{{maintainFacility.countMoneyElectricity?.toLocaleString() }}円</span></div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-row :gutter="[16,16]" class="mt-4" justify="space-between">
        <a-col class="gutter-row" :span="16">
            <div class="gutter-box d-flex justify-content-center" style="max-height: 400px">
                <div class="info1 my-4 ms-4" style=" cursor: pointer; align-items: center; width:50%; ">
                    <a href="https://showtakun.com/contents/info1.html" target="_blank">
                        <img style="width: 100%; height: 100%;" src="https://showtakun.com/contents/img/info1.png" alt="Logo">
                    </a>
                </div>

                <div class="info1 m-4" style=" cursor: pointer; align-items: center; width:50%;">
                    <a href="https://showtakun.com/contents/info2.html" target="_blank">
                        <img style="width:100%; height: 100%" src="https://showtakun.com/contents/img/info2.png" alt="Logo">
                    </a>
                </div>

            </div>
        </a-col>
      <a-col :span="8" class="gutter-row ">
        <div class="gutter-box" style="height: 100%">
                  <div class="report pt-4 ps-4  fs-6">
                      <span>省エネ笑太くんへのご意見・ご質問は</span><span><a href="https://pro.academyschool.jp/r/T6WLSMEk9Akn/register" target="_blank">こちらから</a></span>
                  </div>
                  <div class="report pt-1 ps-4 fs-6" style="min-height: 200px">
                      <span>環境レポートはこちらから</span><span><a href="https://showtakun.com/manual/company-admin/report-corpadmin/" target="_blank">こちらから</a></span>
                  </div>
        </div>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<style scoped>
.gutter-box {
  background-color: white;
  min-height: 260px;
}

.ant-select-item-option-content {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home {
  height: 350px;
  width: 810px;
  background-color: white;
}

.home div {
  font-size: 20px;
  padding-left: 540px;
  cursor: pointer;
  margin-top: 20px;
  color: #1890ff;
}

ul .title {
  margin-left: -20px;
}

.title-facility {
  font-size: 20px;
  margin-left: 30px;
}

.title-facility li {
  font-size: 15px;
  margin-top: 30px;
  list-style: none;
}

.box {
  min-height: 250px;
}

.title-emissions, .title-expenses {
  padding-left: 30px;
  font-style: normal;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
}

.title-topic, .title-co2-month {
  padding-left: 25px;
  font-style: normal;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
}

.edit-topic {
  margin-top: -3px !important;
  font-size: 18px;
  color: #1890FF;
  cursor: pointer;
  text-align: left;
}

.detail-chart {
  font-size: 17px;
  color: #1890FF;
  cursor: pointer;
  padding-right: 5px;
}

.lable-topic {
  font-size: 27px;
  text-align: left;
  word-wrap: break-word;
  max-width: 100%;
  overflow: auto;
  padding-top: 50px;
  padding-left: 15px !important;
  white-space: pre-line;
}

.title-co2, .expenses {
  font-style: normal;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);

}

.hr {
  padding-left: 30px;
  padding-right: 40px;
}

.number-co2, .number-expenses {
  font-size: 31px;
  font-style: normal;
}

.faciliti {
  padding-left: 30px;
  font-size: 18px;
}

.total-year {
  padding-left: 30px;
  font-size: 18px;
}

.lable-number {
  color: rgba(0, 0, 0, 0.65);
}

.alarm-lef {
  background-color: white;
}

.alarm-top {
  min-height: 432px;
}

.alarm-bottom {
  background-color: white;
  min-height: 360px;
}

.caret-up {
  color: #F5222D;
}

.caret-down {
  color: #52C41A;
}

.fac1 {
  padding-left: 40px;
}

.title-chart {
  color: red;
}

select {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  line-height: inherit;
  font-weight: 300;
}

.apexcharts-legend, .title-calendar, .title-alarm {
  font-size: 20px;
}

.apexcharts-graphical {
  margin-left: 100px !important;
}

.name-facility {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.facility {
  width: 400px;
  word-wrap: break-word;
  white-space: pre-line;
}

.amount-lastyear {
  margin-left: -28px;
}

.title-facility .item {
    margin-left: 72px;
    min-width: 55px;
}

.title-facility .title-item {
    min-width: 96px;
}

.amount-lastyear .title-item {
    min-width: 96px;
}

</style>

<script>
import {
  CaretDownOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined
} from "@ant-design/icons-vue";
import {CaretUpOutlined} from "@ant-design/icons-vue";
import {EditOutlined} from "@ant-design/icons-vue";
import VueApexCharts from "vue3-apexcharts";
import {BorderOutlined} from "@ant-design/icons-vue";
import BarChart from "../charts/BarChart.vue";
import Calendar from "../charts/Calendar.vue";
import Chart from "../charts/Chart.vue";
import {HomeModel} from "@/model/home";
import {message} from "ant-design-vue";
import {FacilityModel} from "@/model/facility";
import {ref} from "vue";
import dayjs from "dayjs";

import('dayjs/locale/ja')
import Info from './header/Info.vue'

// the default (unselectable) value in
// the months options:
const DEFAULT_MONTH_VALUE = () => ({
  text: new Date().getMonth() + 1,
  value: null,
  disabled: true,
})

const DEFAULT_YEAR_VALUE = () => [
  2021,
  2022,
  2023,
  2024,
  2025,
]

const MONTH_VALUE = () => [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
]
export default {
  components: {
    MenuUnfoldOutlined, DownOutlined, MenuFoldOutlined,
    SearchOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    EditOutlined,
    VueApexCharts,
    BorderOutlined,
    BarChart,
    Calendar,
    Chart,
    Info
  },

  data() {
    const visible = ref(false);
    const showModal = () => {
      visible.value = true;
    };
    const handleOk = e => {
      visible.value = false;
    };
    return {
      isLoading: true,
      loadChart: true,
      visible,
      showModal,
      handleOk,
      data: [],
      dataFacility: [],
      totalMoney: [],
      role: "facility",
      facility: null,
      year: null,
      date: null,
      countcO2ByMonth: null,
      countcO2ByFacility: null,
      alarm: [],
      popup: false,
      titleFacility: {
        topic: null,
      },
      dateYear: {
        year: null,
        month: null,
      },
      dateFormat: '/MM',
      dateYearByFacility: {
        year: null,
        month: null,
      },
      total_co2_lastmonth: true,
      total_co2_lastyear: true,
      total_money_lastmonth: true,
      total_money_lastyear: true,
      account: [],
      maintainFacility: [],
      company_account: null,
      cardYear: new Date().getFullYear(),
      cardYear2: new Date().getFullYear(),
      month: new Date().getMonth(),
      monthCo2: new Date().getMonth(),
      cardMonth: DEFAULT_MONTH_VALUE(),
      cardMonth2: DEFAULT_MONTH_VALUE(),
      cardYearOptions: DEFAULT_YEAR_VALUE(),
      monthOptions: MONTH_VALUE(),
      cardYearOptions2: DEFAULT_YEAR_VALUE(),
      currMonth: dayjs().format('MM'),
      currYear: dayjs().format('YYYY'),
      currDay: dayjs().format('DD'),
      currDD: dayjs().locale('ja').format('dddd'),

      series: [{
        name: '電力',
        data: [123, 52, 36, 37, 22, 43, 21]
      }, {
        name: '上水',
        data: [43, 123, 54, 52, 13, 43, 32]
      }, {
        name: '下水',
        data: [22, 65, 123, 9, 15, 11, 20]
      }, {
        name: 'Item 4',
        data: [22, 65, 123, 9, 15, 11, 20]
      }, {
        name: 'Item 5',
        data: [23, 56, 76, 8, 123, 9, 4]
      }],
      chartOptions: {
        colors: ['#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#E8684A'],
        chart: {
          dropShadow: {
            enabled: true,
            enabledOnSeries: undefined,
            top: 1000,
            left: 2000,
            blur: 3,
            color: '#000',
            opacity: 0.35
          },
          type: 'bar',
          height: 550,
          stacked: true,
          marginLeft: 500,
          width: 300,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {}
          },
        },
        stroke: {
          width: -5,
          colors: ['#fff']
        },
        // title: {
        //     text: 'Fiction Books Sales'
        // },
        xaxis: {
          categories: ["施設A", "施設B", "施設C", "Text label", "Text label", "Text label", "Text label"],
          fontsize: 560,
          labels: {
            formatter: function (val) {
              return null
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
      columns: [
        {
          title: '次回アラーム日',
          dataIndex: 'next_alarm_date',
          key: 'next_alarm_date',
        },
        {
          title: '次回実施日',
          dataIndex: 'next_maintenance_date',
          key: 'next_maintenance_date',
        },
        {
          title: '設備名称',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '内容',
          dataIndex: 'alarm_content',
          key: 'alarm_content',
        }
      ],

    }
  },
  computed: {
    minCardYear() {
      return new Date().getFullYear()
    },
    minCardMonth() {
      return new Date().getMonth()
    },
  },
  methods: {
    async getMonth() {
      this.isLoading = true
      this.dateYear.year = this.cardYear;
      this.dateYear.month = ("0" + this.month).slice(-2);
      localStorage.setItem('date-facility', JSON.stringify(this.cardYear + '-' + this.dateYear.month))
      this.dateYear.facilityID = this.facility;
      await HomeModel.getListFacility(this.dateYear).then(res => {
        this.data = res?.totalCo2;
        this.totalMoney = res?.totalMoney;
        this.countcO2ByMonth = res?.countcO2ByMonth;
        this.alarm = res?.listAlarm;
        this.maintainFacility = res?.maintainFacility;
        this.isLoading = false

      });

      if (this.data?.total_co2_lastmonth <= 0) this.total_co2_lastmonth = false;
      else this.total_co2_lastmonth = true;
      if (this.data?.total_co2_lastyear <= 0) this.total_co2_lastyear = false;
      else this.total_co2_lastyear = true;
      if (this.totalMoney?.total_money_lastmonth <= 0) this.total_money_lastmonth = false;
      else this.total_money_lastmonth = true;
      if (this.totalMoney?.total_money_lastyear <= 0) this.total_money_lastyear = false;
      else this.total_money_lastyear = true;
    },
    async getByFacility() {
      this.loadChart = true
      this.dateYearByFacility.year = this.cardYear2;
      this.dateYearByFacility.month = ("0" + this.monthCo2).slice(-2);
      localStorage.setItem('date-chart-facility', JSON.stringify(this.dateYearByFacility.year + '-' + this.dateYearByFacility.month))
      this.dateYearByFacility.facilityID = this.facility;
      await HomeModel.getListByBuilding(this.dateYearByFacility).then(res => {
        this.countcO2ByFacility = res.countCo2ByFacility;
        this.loadChart = false
      });
    },
    async updateTopic() {
      this.clearError()
      FacilityModel.updateOne(this.titleFacility, this.facility)
          .then(res => {
            if (res?.response?.status === 422) {
              this.tryGetErrorResponse(res)
            } else {
              message.success(res.message)
              this.$router.go(this.$router.currentRoute)
            }
          })
    },
  },

  async mounted() {
    let user = await this.$store.dispatch('auth/me')
    this.facility = this.$router.currentRoute.value.params.facilityId
    if (!this.facility) {
      this.facility = user.facility_id;
    }
    await this.getMonth();
    await this.getByFacility();
  }

}
</script>
