<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">使用状況一覧</div>
    <a-button type="primary" size="large" ghost @click="$router.push({ name: 'usage.create' })">
      新規作成
    </a-button>
  </div>
  <div class="content">
    <a-form>
      <a-row :gutter="24">
        <a-col :span="4">
          <a-form-item label="種別">
            <a-select
                v-model:value="searchParams.energyType"
                :disabled="isLoading"
                @keyup.enter="getList(1, {...searchParams})"
                :fieldNames="{label: 'name', value: 'id'}"
                show-search
                :filter-option="filterOption"
                :options="energyTypes"/>
          </a-form-item>
        </a-col>

        <a-col :span="5" v-if="this.$store.state.role.role === '管理者'">
          <a-form-item label="代理店">
            <a-select
                v-model:value="searchParams.agency"
                :disabled="isLoading"
                @keyup.enter="getList(1, {...searchParams})"
                :fieldNames="{label: 'name', value: 'id'}"
                :options="agencies"
                show-search
                :filter-option="filterOption"
                @change="filterCompanyByAgency"/>
          </a-form-item>
        </a-col>

        <a-col :span="5"
               v-if="!this.$store.state.role.role.includes('企業') && !this.$store.state.role.role.includes('施設')">
          <a-form-item label="企業">
            <a-select
                v-model:value="searchParams.company"
                :disabled="isLoading"
                @keyup.enter="getList(1, {...searchParams})"
                :fieldNames="{label: 'name', value: 'id'}"
                :options="companies"
                show-search
                :filter-option="filterOption"
                @change="filterSelectFacilityByCompany"/>
          </a-form-item>
        </a-col>

        <a-col :span="5" v-if="!this.$store.state.role.role.includes('施設')">
          <a-form-item label="施設:">
            <a-select
                v-model:value="searchParams.facility"
                :disabled="isLoading"
                @keyup.enter="getList(1, {...searchParams})"
                :fieldNames="{label: 'name', value: 'id'}"
                :options="facilities"
                show-search
                :filter-option="filterOption"
                @change="filterSelectBuilding"/>
          </a-form-item>
        </a-col>

        <a-col :span="5">
          <a-form-item label="建屋">
            <a-select
                v-model:value="searchParams.building"
                :disabled="isLoading"
                @keyup.enter="getList(1, {...searchParams})"
                :fieldNames="{label: 'name', value: 'id'}"
                show-search
                :filter-option="filterOption"
                :options="buildings"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="text-end">
        <a-col :span="4" :offset="20" class="text-end">
          <a-button type="primary" :loading="isLoading" @click="getList(1, {...searchParams})">
            <template #icon>
              <SearchOutlined/>
            </template>
            検索
          </a-button>
        </a-col>
      </a-row>
    </a-form>

    <a-table
        :scroll="{ x: 2000 }"
        :loading="isLoading"
        :dataSource="dataSource"
        :columns="tableColumns"
        :pagination="pagination"
        @change="handleTableChange">
      <template #bodyCell="{column, text, record}">
        <template v-if="column.dataIndex === 'type'">
          {{ record.energy_type?.name }}
        </template>
        <template v-if="column.dataIndex === 'company' && record.company_id">
          <router-link :to="{ name: 'company.edit', params: {companyId: record?.company?.id }}">
            {{ record.company?.name }}
          </router-link>
        </template>
        <template v-if="column.dataIndex === 'facility' && record.facility_id">
          <router-link :to="{ name: 'facility.edit', params: {facilityId: record?.facility_id }}">
            {{ record.facility?.name }}
          </router-link>
        </template>
        <template v-if="column.dataIndex === 'building' && record.building_id">
          <router-link :to="{ name: 'building.edit', params: {buildingId: record?.building_id }}">
            {{ record.building?.name }}
          </router-link>
        </template>
        <template v-if="column.dataIndex === 'expiry'">
          {{ record.usage_from_date }} ~ {{ record.usage_to_date }}
        </template>
        <template v-if="column.dataIndex === 'money'">
          {{ Math.abs(record.money).toLocaleString() }}
        </template>
        <template v-if="column.dataIndex === 'amount'">
          {{ Math.abs(record.amount).toLocaleString() }}
        </template>
        <template v-if="column.dataIndex === 'converted_co2_amount'">
          {{ Math.abs(record.converted_co2_amount).toLocaleString() }}
        </template>
        <template v-if="column.dataIndex === 'action'">
          <router-link :to="{ name: 'usage.edit', params: {usageId: record.id }}">詳細</router-link> |
          <router-link :to="{ name: 'usage.create', query: {copyId: record.id }}">コピー</router-link>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import {
  SearchOutlined
} from '@ant-design/icons-vue'
import {CompanyModel} from "@/model/company"
import {FacilityModel} from "@/model/facility"
import {BuildingModel} from "@/model/building"
import {agency} from "@/model/agency";
import {UsageModel} from "@/model/usage";
import {EnergyContractModel} from "@/model/energyContract";
import VueLocalStorage from 'vue-localstorage'

const defaultSelectOption = {
  name: '全て',
  id: null
}
export default {
  components: {
    SearchOutlined
  },
  data() {
    const handleTableChange = (pag, filters, sorter) => {
      this.isLoading = true
      this.getList(pag.current, {...this.searchParams}, sorter, pag.pageSize)
      this.isLoading = false
    };

    return {
      isLoading: true,
      handleTableChange,
      dataSource: [],
      pagination: {},
      searchParams: {
        searchKey: null,
        agency: null,
        company: null,
        facility: null,
        building: null,
        energyType: null
      },
      agencies: [],
      companies: [],
      facilities: [],
      buildings: [],
      energyTypes: [],
      tableColumns: [
        {
          title: '種別',
          dataIndex: 'type',
          ellipsis: true
        },
        {
          title: '企業',
          dataIndex: 'company',
          ellipsis: true
        },
        {
          title: '施設',
          dataIndex: 'facility',
          ellipsis: true
        },
        {
          title: '建屋',
          dataIndex: 'building',
          ellipsis: true
        },
        {
          title: '使用期間',
          dataIndex: 'expiry',
          sorter: true,
          width: 250
        },
        {
          title: '使用量',
          dataIndex: 'amount'
        },
        {
          title: 'CO2量(t)',
          dataIndex: 'converted_co2_amount'
        },
        {
          title: '金額',
          dataIndex: 'money'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 150,
          fixed: 'right',
        }
      ]
    }
  },
  async mounted() {
    this.energyTypes = [defaultSelectOption, ...await EnergyContractModel.getListEnergyType()]
    let user = await this.$store.dispatch('auth/me')

    if (this.$store.state.role.role === '管理者') {
      this.agencies = [defaultSelectOption, ...await agency.getAllAgencyNames()]
      this.companies = [defaultSelectOption, ...await CompanyModel.getAllCompanyNames()]
      this.facilities = [defaultSelectOption, ...await FacilityModel.getAllFacilityNames()]
    } else {
      this.searchParams.agency = user.agency_id
      this.searchParams.company = user.company_id
      this.searchParams.facility = user.facility_id
      this.companies = [defaultSelectOption, ...await CompanyModel.getCompanyByAgency(user.agency_id)]
      this.facilities = [defaultSelectOption, ...await FacilityModel.getFacilityByParent(user.agency_id, user.company_id)]
    }

    this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
      agency_id: this.searchParams.agency ?? 0,
      company_id: this.searchParams.company ?? 0,
      facility_id: this.searchParams.facility ?? 0
    })]
    if (localStorage.getItem('usage_Flag') == 0) {
        this.getList(localStorage.getItem('usage_pages'), this.searchParams);
    } else {
      this.searchParams = JSON.parse(localStorage.getItem('usage_searchParams'));
      this.getList(localStorage.getItem('usage_pages'), this.searchParams);
    }
  },
  methods: {
    getList(page, params = {
      searchKey: null,
      agency: 0,
      company: 0,
      facility: 0,
      building: 0,
      energyType: 0
    }, sorter = null, perPage = 10) {
      this.isLoading = true
      UsageModel.listUsage({
        page,
        perPage,
        searchKey: params.searchKey,
        agency: params.agency,
        company: params.company,
        facility: params.facility,
        building: params.building,
        energyType: params.energyType,
        ...sorter
      })
          .then(res => {
            this.dataSource = res.data
            this.pagination = {
              total: res.total,
              current: res.current_page,
              pageSize: res.per_page,
            }
            this.isLoading = false
            localStorage.setItem('usage_pages',res.current_page)
            localStorage.setItem('usage_Flag',1)
            localStorage.setItem('usage_searchParams',JSON.stringify(this.searchParams))
          })
      return true
    },

    async filterCompanyByAgency() {
      this.searchParams.company = null
      this.searchParams.facility = null
      this.searchParams.building = null
      this.companies = [defaultSelectOption, ...await CompanyModel.getCompanyByAgency(this.searchParams.agency ?? 0)]
      this.facilities = [defaultSelectOption, ...await FacilityModel.getFacilityByParent(this.searchParams.agency ?? 0, this.searchParams.company ?? 0)]
      this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
        agency_id: this.searchParams.agency ?? 0,
        company_id: this.searchParams.company ?? 0,
        facility_id: this.searchParams.facility ?? 0
      })]
    },

    async filterSelectFacilityByCompany() {
      this.searchParams.facility = null
      this.searchParams.building = null
      this.facilities = [defaultSelectOption, ...await FacilityModel.getFacilityByParent(this.searchParams.agency ?? 0, this.searchParams.company ?? 0)]
      this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
        agency_id: this.searchParams.agency ?? 0,
        company_id: this.searchParams.company ?? 0,
        facility_id: this.searchParams.facility ?? 0
      })]
    },

    async filterSelectBuilding() {
      this.searchParams.building = null
      this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
        agency_id: this.searchParams.agency ?? 0,
        company_id: this.searchParams.company ?? 0,
        facility_id: this.searchParams.facility ?? 0
      })]
    },

    filterOption(input, option) {
      try {
           return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      } catch {
           return false;
      }
    }
  }
}
</script>
