<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">図面詳細</div>
  </div>
  <div class="content">
    <a-form :label-col="labelCol">
      <a-row :gutter="24">
        <a-col class="px-3" :span="12">
          <a-col :span="24" v-if="this.$store.state.role.role === '管理者'">
            <a-form-item
                :rules="[{ required:true}]"
                label="代理店">
              <a-select
                  v-model:value="map.agency_id"
                  show-search
                  :disabled="isLoading"
                  :fieldNames="{ label: 'name', value: 'id' }"
                  @change="filterCompanyByAgency"
                  :options="agencies"/>
              <span class="text-danger" v-if="isInvalid('agency_id')">
                                {{ invalidMessages('agency_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24"
                 v-if="!this.$store.state.role.role.includes('企業') && !this.$store.state.role.role.includes('施設')">
            <a-form-item
                :rules="[{ required:true}]"
                label="企業">
              <a-select
                  show-search
                  :disabled="isLoading"
                  :fieldNames="{ label: 'name', value: 'id' }"
                  :options="companies"
                  v-model:value="map.company_id"
                  @change="filterSelectFacilityByCompany"
                  :class="[isInvalid('company_id') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('company_id')">
                                    {{ invalidMessages('company_id')[0] }}
                                </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="施設名"
                :required="true"
                v-if="!this.$store.state.role.role.includes('施設')">
              <a-select
                  v-model:value="map.facility_id"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="listFacility"
                  :disabled="isLoading"
                  @change="filterSelectBuilding(2)"
                  :class="[isInvalid('facility_id') ? 'border-danger' : '']"
              />
              <span class="text-danger" v-if="isInvalid('facility_id')">
                            {{ invalidMessages('facility_id')[0] }}
                        </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
                <a-form-item
                    label="建屋名">
                    <a-select
                        placeholder="建屋名"
                        v-model:value="map.building_id"
                        :disabled="isLoading"
                        :fieldNames="{label: 'name', value: 'id'}"
                        :options="buildings"
                        :class="[isInvalid('building_id') ? 'border-danger' : '']"/>
                    <span class="text-danger" v-if="isInvalid('building_id')">
                                {{ invalidMessages('building_id')[0] }}
                            </span>
                </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :required="true"
                label="図面区分"
            >
              <a-select
                  v-model:value="map.drawing_type_id"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="listDrawing"
                  :disabled="isLoading"
                  :class="[isInvalid('drawing_type_id') ? 'border-danger' : '']"
              />
              <span class="text-danger" v-if="isInvalid('drawing_type_id')">
                            {{ invalidMessages('drawing_type_id')[0] }}
                        </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="map.drawing_type_id === 0">
            <a-form-item
                label="図面区分"
                :required="true">
              <a-input
                  v-model:value="map.drawing_type"
                  :disabled="isLoading"/>
              <span class="text-danger" v-if="isInvalid('drawing_type')">
                                {{ invalidMessages('drawing_type')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :required="true"
                label="図面番号"
            >
              <a-input
                  v-model:value="map.code"
                  :disabled="isLoading"
              />
              <span class="text-danger" v-if="isInvalid('code')">
                            {{ invalidMessages('code')[0] }}
                        </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="保管場所">
              <a-input
                  v-model:value="map.storage_location"
                  :disabled="isLoading"
              />
              <span class="text-danger" v-if="isInvalid('storage_location')">
                            {{ invalidMessages('storage_location')[0] }}
                        </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="作成日">
              <a-date-picker
                  :disabled="isLoading"
                  :value-format="dateFormat"
                  v-model:value="map.date_created"
                  @change="handleChange"
                  :change-on-clear="true"
              />
              <span class="text-danger" v-if="isInvalid('date_created')">
                            {{ invalidMessages('date_created')[0] }}
                        </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :required="true"
                label="作成者">
              <a-input
                  v-model:value="map.creator"
                  :disabled="isLoading"
              />
              <span class="text-danger" v-if="isInvalid('creator')">
                            {{ invalidMessages('creator')[0] }}
                        </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="摘要">
              <a-textarea
                  v-model:value="map.note"
                  :disabled="isLoading"
              />
              <span class="text-danger" v-if="isInvalid('note')">
                            {{ invalidMessages('note')[0] }}
                        </span>
            </a-form-item>
          </a-col>
        </a-col>

        <a-col class="px-3" :span="12">
          <a-col :span="24">
            <a-form-item
                label="ファイル添付"
            >
              <input type="file" ref="upload_file" @change="uploadFile" hidden="hidden"
                     multiple="multiple"/>
              <a-button :disabled="isLoading" @click="$refs.upload_file.click()">
                <UploadOutlined/>
                Upload
              </a-button>
            </a-form-item>



      <div >
         図面   ファイルサイズ  
      </div>
            <div class="offset-2 ps-2em list-item">
              <li class="position-relative" v-for="(item, index) in listFile">
                <a-button :disabled="isLoading" type="link" primary
                          @click="downloadFile(item.path,item.name)">
                  <img v-bind:src=item.abspath width="96" height="65"/>
                  {{ item.name }}
                </a-button>
                <a-button :disabled="isLoading" class="delete-file-btn end-0 d-none"   @click="deleteFile(item.path)" type="link" primary>
                  <delete-outlined/>
                </a-button>
              </li>
            </div>
            <span class="text-danger" v-if="isInvalid('files')">
                            {{ invalidMessages('files')[0] }}
                        </span>
          </a-col>
        </a-col>
      </a-row>

      <div class="text-center">
        <a-button class="mx-2" :loading="isLoading" @click="$router.push({ name: 'map.list' })"
                  danger>キャンセル
        </a-button>
        <a-button class="mx-2" type="primary" :loading="isLoading" @click="this.visible = true" danger>削除
        </a-button>
        <a-button class="mx-2" type="primary" :loading="isLoading" @click="update">更新</a-button>
      </div>

    </a-form>

    <a-modal
        v-model:visible="visible"
        title="操作確認"
        ok-text="はい"
        cancel-text="いいえ"
        @ok="deleteMap"
        @cancel="this.visible = false"
        :bodyStyle="{ borderRadius: '5px' }"
        :width="400"
        :okButtonProps="{ type: 'danger' }"
    >
      <p>
        <ExclamationCircleOutlined class="larger-icon"/>
        削除してもよろしいでしょうか?
      </p>
    </a-modal>

  </div>
</template>

<script>
import {DeleteOutlined, ExclamationCircleOutlined, PaperClipOutlined, UploadOutlined} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import {FacilityModel} from "@/model/facility";
import mapApi from '@/model/map';
import {CompanyModel} from "@/model/company";
import {agency} from "@/model/agency";
import {BuildingModel} from "@/model/building";

export default {
  components: {
    ExclamationCircleOutlined,
    DeleteOutlined,
    UploadOutlined,
    PaperClipOutlined,
  },
  data() {
    return {
      files: null,
      isLoading: true,
      visible: false,
      labelCol: {span: 5},
      listFile: null,
      listFacility: [],
      buildings: [],
      listDrawing: [],
      map: {},
      dateFormat: 'YYYY/MM/DD',
      companies: [],
      agencies: [],
    }
  },
  methods: {
    update() {
      this.clearError()
      this.isLoading = true
      mapApi.update(this.map.id, this.map).then(res => {
        if (res?.response?.status === 422) {
          this.tryGetErrorResponse(res)
        } else {
          message.success(res.message)
          this.$router.push({name: 'map.list'})
        }
        this.isLoading = false
      })
    },

    async downloadFile(link,name) {
      let mapId = this.$router.currentRoute.value.params.mapId
      let param = {
        path: link,
      }
      mapApi.downloadFile(mapId, param)
          .then(res => {
            if (res.status) {
              let element = document.createElement('a');
              element.setAttribute('href', res.data);
              element.setAttribute('download', name);
              element.style.display = 'none';
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
            } else {
              message.error(res.message)
            }
          })
    },

  getDetail() {
      let mapId = this.$router.currentRoute.value.params.mapId
      mapApi.detail(mapId).then(async res => {
        let fileList = []
        if (res.data) {
          this.listFile = res.data.listFile
        }

        if (res && res.success === false) {
          message.error(res.message)
          this.$router.push({name: 'map.list'})
        } else {
          let user = await this.$store.dispatch('auth/me')
          let checkRole = await this.$store.dispatch('role/checkRole', {
            user: user,
            data: res
          })
          if (this.$store.state.role.role === '管理者' || checkRole) {
            this.map = res.data
            this.filterSelectBuilding(1)
          } else {
            message.error(`アクセス権限がありません。`)
            this.$router.push({name: 'map.list'})
          }
        }
        this.isLoading = false
      })
/*
    mapApi.FileSize(mapId).then(async res => {
          if (res.data) {
            this.filesize = res.data
          }
      })
*/
    },

    uploadFile(e) {
      this.isLoading = true
      let mapId = this.$router.currentRoute.value.params.mapId
      let params = {
        id: mapId,
      }
      let files = e.target.files
      mapApi.uploadFile(params, files)
          .then(res => {
            if (res.status) {
              this.getDetail()
              message.success(res.message)
            } else {
                if (res.response.status === 413){
                    message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
                    this.isLoading = false
                }else {
                    message.error(res.message)
                }
            }
          }).catch(error => {
              if (error.response && error.response.status === 413) {
                  message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
              }
          })
    },

    deleteFile(path) {
      this.isLoading = true
      let mapId = this.$router.currentRoute.value.params.mapId
      let param = {
        path: path
      }
      mapApi.deleteFile(mapId, param)
          .then(res => {
            if (res.status) {
              this.getDetail()
              message.success(res.message)
            } else {
              message.error(res.message)
            }
          })
    },

    deleteMap() {
      this.isLoading = true
      mapApi.deleteMap(this.map.id)
          .then(res => {
            if (res && res.status === true) {
              message.success('削除されました。')
              this.$router.push({name: 'map.list'})
            } else {
              message.error(res.message)
            }
            this.isLoading = false
          })
    },

    async filterCompanyByAgency() {
      this.map.company_id = null
      this.map.facility_id = null
      this.companies = [...await CompanyModel.getCompanyByAgency(this.map.agency_id ?? 0)]
      this.listFacility = [...await FacilityModel.getFacilityByParent(this.map.agency_id ?? 0, this.map.company_id ?? 0)]
    },

    async filterSelectFacilityByCompany() {
      this.map.facility_id = null
      this.listFacility = [...await FacilityModel.getFacilityByParent(this.map.agency_id ?? 0, this.map.company_id ?? 0)]
    },

    async filterSelectBuilding($type) {
      if ($type == 2){
          this.map.building_id = null
      }
      this.buildings = await BuildingModel.getListBuilding({
          agency_id: this.map.agency_id ?? 0,
          company_id: this.map.company_id ?? 0,
          facility_id: this.map.facility_id ?? 0
      })
    },

     handleChange(date) {
          if (date === null) {
              this.map.date_created = 0;
          }
      }
  },

  async mounted() {
    this.listDrawing = [...await mapApi.listDrawingType()]
    let user = await this.$store.dispatch('auth/me')

    if (this.$store.state.role.role === '管理者') {
      this.agencies = await agency.getAllAgencyNames()
      this.companies = await CompanyModel.getAllCompanyNames()
      this.listFacility = await FacilityModel.getAllFacilityNames()
    } else {
      this.map.agency_id = user.agency_id
      this.map.company_id = user.company_id
      this.map.facility_id = user.facility_id
      this.companies = await CompanyModel.getCompanyByAgency(user.agency_id)
      this.listFacility = await FacilityModel.getFacilityByParent(user.agency_id, user.company_id)
    }

    this.getDetail()
  },

}
</script>

<style scoped lang="scss">
.list-item > li:hover {
  .delete-file-btn {
    display: inline-block !important;
  }

}
</style>
